
:root {
  --primary-color: #b42c2c;
  --bg-color: #f4f7fa;
  --text-color: #f5f0f0;
  --sidebar-bg: #fae3df;
  --sidebar-hover: #e6f0ff;
}
.sidebar {
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: var(--sidebar-bg);
  overflow-x: hidden;
  transition: 0.3s;
  box-shadow: 0 0 15px rgba(0,0,0,0.1);
  white-space: nowrap;
}
.sidebar-header {
  border-bottom: 1px solid #e0e0e0;
}

.toggle-btn {
  background: none;
  border: none;
}
.sidebar a {
  padding: 15px;
  text-decoration: none;
  font-size: 16px;
  color:black;
  display: flex;
  align-items: center;
  transition: 0.2s;
}
.sidebar a:hover {
  color: var(--primary-color);
}

#main {
  transition: margin-left .3s;
  margin-left: 250px;
}

.icon-hover{
  color: black;
}
.icon-hover:hover{
  color: #b42c2c;
}