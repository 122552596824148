.footer_black {
    padding-top: 12px;
    background-color: #d47bb1;
  }
  
  .footer_black .widgets_container > h3 {
    color: #0c0c0c;
    font-family: serif;
    font-weight: 400;
    font-size: 25px;
    text-transform: capitalize;
    margin-bottom: 24px;
  }
  
 

  .footer_contact ul li a {
    color: #fff;
    font-size: 18px;
    width: 40px;
    height: 60px;
    line-height: 40px;
    border: 0;
    background: #2f2f2f;
    text-align: center;
    border-radius: 50%;
    display: block;
  }
  

  
  .footer_menu ul li a {
    display: block;
    font-weight: 400;
    line-height: 50px;
    font-size: 14px;
    color: #0c0c0c;
  }
  
  
  /* ***************** */
  .simple_product_items {
    display: flex;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid 2d2d2d;
  }
  
  .simple_product_items:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;
  }
  
  .simple_product_thumb {
    width: 22%;
  }
  
  .simple_product_content {
    width: 70%;
    margin-left: 30px;
  }

  .simple_product_content .product_name h3 {
    font-size: 12px;
    line-height: 26px;
    margin-bottom: 10px;
  }
  
  .simple_product_content .product_name h3 a {
    color: #0e0d0d;
    font-size: 18px;
  }
  
  .simple_product_content .product_name h3 a:hover {
    color: rgb(8, 8, 8);
  }
  
  .simple_product_content .product_price span {
    
    font-size: 12px;
    font-weight: 400;
  }
  
  .simple_product_content .product_price span.old_price {
    text-decoration: line-through;
    padding-right: 18px;
  }
  
  .simple_product_content .product_price span.current_price {

    font-weight: 500;
  }
  
  /* *********footer bottom ******* */
  .footer_bottom {
    padding: 20px 0 15px;
  }
  
  .copyright_area {
    text-align: center;
  }
  .copyright_area p {
    text-transform: capitalize;
    color: black;
  }
  
  .copyright_area p a {

    text-decoration: none;
  }
  .copyright_area p a:hover {
    text-decoration: underline;
  }