/* landing header */

.nav-link {
  position: relative;
  text-decoration: none; /* Remove default underline */
}

.nav-link:hover::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px; /* Adjust this value to control the vertical position of the underline */
  height: 2px; /* Adjust this value for the thickness of the underline */
  background-color:black; /* Set the underline color */
  transition: width 0.3s, left 0.3s; /* Transition for smooth effect */
  width: 100%; /* Full width on hover */
}










/* top images */
.image-overlay {
  position: relative;
  text-align: center;
  color: black; 
}

.background-images {
  display: flex;
  height: 600px; 
  overflow: hidden; 
}

.background-images img {
  width: 50%; 
  height: 100%; 
  object-fit: cover; 
}

.image-overlay h1 {
  position: absolute; 
  top: 60%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  z-index: 1; 
}

.shop-now {
  position: absolute; 
  top: 80%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  z-index: 1; 
  margin-top: 10px; 
  padding: 12px 24px; 
  background-color: #ff5722; 
  color: white; 
  border: none; 
  border-radius: 20px; 
  font-size: 1em; 
  cursor: pointer; 
  transition: background-color 0.3s ease, transform 0.3s ease; 
}

.shop-now:hover {
  background-color: #e64a19; 
  transform: translate(-50%, -50%) scale(1.05); 
}

@media (max-width: 768px) {
  .shop-now {
    padding: 10px 20px; 
    font-size: 0.9em; 
  }
}

@media (max-width: 768px) {
  .background-images {
    height: 300px; 
  }

  .image-overlay h1 {
    top: 50%; 
    font-size: 1.2em; 
  }

  .shop-now {
    top: 70%; 
    font-size: 1em; 
  }
}




/* middel images */
.container-images {
  display: grid;
  grid-template-columns: 1.5fr 1fr; 
  gap: 1rem; 
  align-items: center; 
}
.a1-image{
  border-radius: 20px;
}
.y1{
  border-radius: 20px;
}



/* modle show*/
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  width: 90%;
  max-width: 400px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
}

.email-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
}

.sign-up-button, .no-thanks-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.sign-up-button {
  background-color: #007bff;
  color: white;
}

.no-thanks-button {
  background-color: #6c757d;
  color: white;
}

.disclaimer {
  margin-top: 10px;
  font-size: 12px;
  color: #6c757d;
}



/* landing header */

.menu-toggle-btn {
  display: none; /* Initially hide the button on larger devices */
  cursor: pointer;
  background: transparent;
  border: none;
}

.menu-drawer {
  display: none; /* Hide the drawer by default */
  position: fixed;
  top: 80px;
  left: 0;
  width: 250px;
  height: 100%;
  background: #f0f0f0; /* Drawer background color */
  transition: transform 0.3s ease;
  transform: translateX(-100%); /* Move it off-screen */
  z-index: 1000;
}

.menu-drawer.open {
  display: block; /* Allow drawer to be displayed when opened */
  transform: translateX(0); /* Bring it back on-screen */
}

.menu-drawer__inner-container {
  padding: 1rem;
}

.menu-drawer__navigation-container {
  margin-bottom: 1rem;
}

.menu-drawer__menu {
  list-style: none;
  padding: 0;
}

@media (max-width: 768px) {
  .menu-toggle-btn {
    display: block; /* Show the button on small devices */
  }

  .menu-drawer {
    display: block; /* Allow drawer to be displayed when opened */
  }
}



/* .moda{
  width: 400px;
  height: 600px;
}

@media (max-width: 768px) {
 

  .modal-content {
    width: 300px; 
    height: 380px;
  }

 
  
  .item1 {
    order: 1;
  }
  
  .item2 {
    order: 0; 
    height: 380px;
    object-fit: cover;
  }
  
} */

