.payment-box {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.scrollable-content {
  max-height: 550px; /* Adjust height as needed */
  overflow-y: auto;
}
