.card-status {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .card-io {
    width: 100%;
    max-width: 400px;
    margin: 20px 0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    overflow: hidden;
  }
  
  .card-success {
    border: 2px solid #28a745;
  }
  
  .card-failure {
    border: 2px solid #dc3545;
  }
  
  .card-body {
    padding: 20px;
  }
  
  .icon-success {
    font-size: 3rem;
    color: #28a745;
    animation: bounce 1s infinite;
  }
  
  .icon-failure {
    font-size: 3rem;
    color: #dc3545;
    animation: shake 0.5s infinite;
  }
  
  .card-title {
    margin-top: 10px;
    font-size: 1.25rem;
  }
  
  .card-text {
    font-size: 1rem;
  }
  
  /* Bounce animation for success */
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
  
  /* Shake animation for failure */
  @keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(0); }
    75% { transform: translateX(5px); }
    100% { transform: translateX(0); }
  }
  