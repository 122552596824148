/* productcart */
.imgas {
  width: 100%;
  height: auto; /* Adjust to maintain aspect ratio */
  max-height: 100%; /* Ensures it doesn't exceed the section height */
  border-radius: 10px; /* Adding border-radius here */
  transition: filter .7s ease-in-out;
}


/* product detail */
.zoomedImageContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.zoomedImage {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
  cursor: pointer;
}


 /* bracelet */
 .poto {
  transition: transform 0.3s ease; 
}

.poto:hover {
  transform: translateY(-10px); 
}

.imo {
  transition: transform 0.8s ease;
  transform-style: preserve-3d;
  backface-visibility: visible;
}

.imo:hover {
  animation: rotate 1.5s infinite linear;
}

@keyframes rotate {
  from {
    transform: rotateY(0);
  }
  to {
    transform: rotateY(360deg);
  }
}




